<template>
  <v-app class="pt-15">
    <customize-header homeRouter="/audiovisualCloud/home"
                      homeTitle="视听云"
                      :links="links"></customize-header>
    <router-view></router-view>
    <customize-suspendBtn></customize-suspendBtn>
    <customize-footer></customize-footer>
  </v-app>
</template>

<script>
export default {
  name: 'audiovisualCloudIndex',
  data: () => ({
    links: [
      {
        title: "首页",
        router: "/audiovisualCloud/home",
        children: []
      },
      {
        title: "解决方案",
        router: "/audiovisualCloud/audioSolution",
        children: []
      },
      // {
      //   title: "案例",
      //   router: "/audiovisualCloud/audioCase",
      //   children: []
      // }
    ],
  }),
  mounted() {

  },
  methods: {
  }
};
</script>
<style lang="scss" scoped>
</style>
